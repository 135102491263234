import React from 'react';
import Column from './Columns/Columns';
import Li from './Columns/li';
import Bottom from './Bottom/Bottom';
import mName from 'static/memberstays_(500_x_500_px)3.png';

const Footer = () => {
  return (
    <footer className='main-panel card border footer-bg p-2' id='footer'>
      <div className='container-fluid container-fluid-90 pb-3'>
        <div className='row'>
          <Column heading='Influencers & Hosts'>
            <Li Link='/memberbutton-influencers' title='For Influencers' />
            <Li
              Link='/memberbutton-influencers-network'
              title='Influencer & Referral Network'
            />
            <Li
              Link='https://app.memberbutton.com/become-influencer-host'
              title='Become Influencer Host'
            />
            <Li Link='/memberbutton-engine' title='For Hotels' />
          </Column>

          <Column heading='Menu'>
            <Li
              Link='https://chat.socialhub.center/memberbutton/channels/hotel-partner-onboarding'
              title='Chat & Support'
            />
            <Li Link='/memberbutton-partnerships' title='Partner With Us' />
            <Li Link='/memberbutton-essence' title='Book Stays' />
          </Column>

          <Column heading='Company'>
            <Li Link='/about' title='About' />
            <Li Link='/policy' title='Policies' />
            <Li Link='/faq' title='FAQ' />
          </Column>

          <div className='col-6 col-sm-3 mt-2'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                <a href='http://memberbutton.com'>
                  <a href='http://memberbutton.com'>
                    <img src={mName} className='img-130x32' alt='logo' />
                  </a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom />
    </footer>
  );
};

export default Footer;
