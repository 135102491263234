import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import FaqPage from 'pages/faq_page';
import HomePage from 'pages/home_page';
import AboutPage from 'pages/about_page';
import HotelPage from 'pages/hotel_page';
import SearchPage from 'pages/search_page';
import PolicyPage from 'pages/policy_page';
import PaymentPage from 'pages/payment_page';
import BannerOnePage from 'pages/banner1_page';
import BannerTwoPage from 'pages/banner2_page';
import NotFoundPage from 'pages/not_found_page';
import BannerFourPage from 'pages/banner4_page';
import BannerFivePage from 'pages/banner5_page';
import BannerThreePage from 'pages/banner3_page';
import ConfirmationPage from 'pages/confirmation_page';

import styles from './routing.module.css';

import buildPath from 'utils/build_path';
import getUrlParams from 'utils/get_url_params';

import routes from './routes';

export default function Routing() {
  const history = useHistory();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(
    function handleChannelRedirect() {
      const { channel } = getUrlParams();
      if (!channel) {
        return;
      }
      let script = document.getElementById('head_script');
      if (script) document.head.removeChild(script);

      const hotelUrl = buildPath('', routes.hotelPage, { channelId: channel });

      history.replace(hotelUrl);
    },
    [history]
  );

  // useEffect(() => {
  //   if (channel === '64b5f6da-a470-4ec9-83d7-6e57e086ea14') {
  //     let script = document.getElementById('head_script');
  //     if (script) document.head.removeChild(script);

  //     script = document.createElement('script');
  //     script.src =
  //       'https://app.boei.help/embed/k/1cb14e61-2070-4420-8171-9449c17790a4';
  //     script.async = true;

  //     document.head.appendChild(script);
  //   } else {
  //     let script = document.getElementById('head_script');
  //     if (script) document.head.removeChild(script);

  //     script = document.createElement('script');
  //     script.src = 'https://cdn.boei.help/hello.js';
  //     script.async = true;

  //     document.head.appendChild(script);
  //   }
  // }, [channel]);

  return (
    <Switch>
      <Route path={routes.default} exact>
        <NotFoundPage />
      </Route>
      <Route path={routes.homePage} exact>
        <HomePage />
      </Route>
      <Route path={routes.searchPage} exact>
        <SearchPage />
      </Route>
      <Route path={routes.faqPage} exact>
        <FaqPage />
      </Route>
      <Route path={routes.aboutPage} exact>
        <AboutPage />
      </Route>
      <Route path={routes.bannerOnePage} exact>
        <BannerOnePage />
      </Route>
      <Route path={routes.bannerTwoPage} exact>
        <BannerTwoPage />
      </Route>
      <Route path={routes.bannerThreePage} exact>
        <BannerThreePage />
      </Route>
      <Route path={routes.bannerFourPage} exact>
        <BannerFourPage />
      </Route>
      <Route path={routes.bannerFivePage} exact>
        <BannerFivePage />
      </Route>
      <Route path={routes.policyPage} exact>
        <PolicyPage />
      </Route>
      <Route path={routes.hotelPage} exact>
        <HotelPage />
      </Route>
      <Route path={routes.paymentPage} exact>
        <PaymentPage />
      </Route>
      <Route path={routes.confirmationPage} exact>
        <button onClick={handlePrint} className={styles.printButton}>
          Print or Save
        </button>
        <ConfirmationPage ref={componentRef} />
      </Route>

      <Route path='*'>
        <Redirect to={routes.default} />
      </Route>
    </Switch>
  );
}
