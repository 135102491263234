import React from "react";
import FAQ from "utils/Faqs/faq_s";

import styles from './about.module.css'

const About = () => {
    return (
        <div className={`container ${styles.content}`}>
            <hr/>
            <hr/>
            <hr/>
            <h4>About</h4>
            <p>{FAQ[0][1]}</p>
            <hr/>
            <p>{FAQ[1][1]}</p>
            <hr/>
        </div>
    )
}

export default About;