const FAQ = [ ['What is so unique about Memberbutton.com?',

'Memberbutton.com provides a free and amazing booking landing page for hotels, resorts, BNB’s or Vacation Rentals so they can add their unique booking promotions fully focused on the promotion itself, the properties can use their booking page as a main booking page for their website or simply use it as a promotions focused booking page ex. holiday promotions, special event, virtual events promotions or even members only promotions.',
'Memberbutton.com will not charge any monthly fees or commission on direct bookings generated by the property’s own website or social channels.',
'Memberbutton’s Extranet is powered by Channex.io - channel manager but with our own portal allowing all properties access to a free channel manager that can connect to the top OTA’s (online travel agents like Booking.com, Agoda, Expedia, AirBNB and HotelBeds). Properties using a PMS (property management system) can connect the Memberbutton Extranet to their PMS as well.'],

['How can Memberbutton drive more bookings for properties?',

'Memberbutton.com’s IBE (Instant Booking Engine) comes fully integrated with SocialHub’s Amplify link & tracking solution making your property accessible to existing promoters in the network.  By using Memberbutton’s booking page you will be part of an influencer and publisher referral network that transforms social influencers, bloggers, and other referral partners to small travel agents who will work hard to promote promotions offered by the properties. All bookings generated from referrals are subject to a commission on materialised referrals.',
'All the properties that use the free booking page provided by Memberbutton.com will be listed on our search engine so anyone can visit Memberbutton.com to find properties and book their next stay.'],

['How can we be sure of conversions?',

'Our goal is simple - we help converting clients into loyal guests and bookers. You set prices and rules for each promotion. Your property will be visible to the entire Memberbutton referral network and SocialHub\'s social network to optimize the booking process and increase revenue and boost the revenue for your property.',
'The influencers and publishers are provided with a unique tracking link to promote your property, and they can earn performance-based income for their efforts. We have allocated 13% of the commissions so that each influencer can earn 10% from direct booking referrals as well as additional income for referring more influencers to the network.',
'We are sure to get you the highest conversion rates in the market by using a booking page to promote your room offers, and the booking page is already integrated an Amplify link tracking solution by SocialHub, so your property can be promoted by tons of influencers that will help you drive bookings, conversions, and new loyal guests.'],


['Is Memberbutton.com mobile friendly?',

'Memberbutton booking engine will reformat itself and adapt to any mobile phone or tablet device. Larger navigation buttons, reformatted content and optimized images appear when the user is on a mobile device, and the Extranet can also mobile friendly, allowing properties to manage rates, and access bookings on the move.',
'No matter what device your clients are on, the guest experience begins with beautiful UX/UI design and an intuitive booking process.'],

['Are you integrated with channels and PMS?',

'Yes, we are here to simplify your property’s operations.',
'Don’t worry about complex installations or difficult settings — Memberbutton’s booking page can be integrated into your website, social network, email promotions as click button and link to the page.',
'Memberbutton’s Extranet is also a Channel Manager so you can seamlessly connect to online travel agents like Booking.com, Agoda, Expedia, AirBNB and HotelBeds etc. Let us know if you need help.']
];

export default FAQ;
