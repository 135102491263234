const composeRoute = (route) => {
  const { REACT_APP_BASE_PATH: basePath = "" } = process.env;

  return `${basePath}${route}`;
};

const routes = {
  homePage: composeRoute("/"),
  faqPage: composeRoute('/faq'),
  aboutPage: composeRoute('/about'),
  policyPage: composeRoute('/policy'),
  searchPage: composeRoute("/search"),
  hotelPage: composeRoute("/:channelId"),
  bannerTwoPage: composeRoute("/memberbutton-engine"), 
  bannerOnePage: composeRoute("/memberbutton-essence"), 
  paymentPage: composeRoute("/:channelId/booking_info"),
  bannerFourPage: composeRoute("/memberbutton-influencers"),
  bannerFivePage: composeRoute("/memberbutton-partnerships"),
  bannerThreePage: composeRoute("/memberbutton-influencers-network"),
  confirmationPage: composeRoute("/:channelId/confirmation/:bookingId"),
  default: composeRoute("/404"),
}; 

export default routes;
