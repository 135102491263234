import React from 'react';

import styles from './banner1.module.css';

const Banner1 = () => {
  return (
    <div className={`row ${styles.celebrate}`}>
      <div className={`col-md-12 ${styles.celebrate_back}`}>
        <h2 className={`font-weight-400 mb-2 text-white`}>
          The essence of Memberbutton
        </h2>
        <p className='text-14 text-white'>
          The Memberbutton as a marketplace facilitates ‘Member Stays’ and
          Experiences <br />
          for everyone in a true win-win concept.. It pairs up hotels with
          capable and dedicated <br />
          lifestyle influencers, while enabling small and individual hotels to
          get their very own <br /> booking system to generate – increased –
          sales.
        </p>
        <a href='/memberbutton-essence'>
          <button className='p-3 rounded-4 border-0 font-weight-500 mt-5'>
            Learn More
          </button>
        </a>
      </div>
    </div>
  );
};

export default Banner1;
