import React from "react";

import Footer from 'components/home_footer'
import BannerFiveText from "components/banner5_text";

import styles from './banner5_page.module.css'

import second from 'static/second.jpg'

import Nabar from "components/Nabar";
import MainSearch from "components/main_search";

const BannerFivePage = () => {

  
  return ( 
    <div>
      <Nabar/>
      <div className='hero-banner magic-ball home'>
        <div className='main-banner'>
          <img src={second} alt='front pic' />
          <MainSearch banner={true}/>
        </div>
      </div>

        <BannerFiveText/>

      <div className={styles.footer}>
          <Footer/>
      </div> 
    </div>
  );
}

export default BannerFivePage;