import React from 'react';
import styles from './banner3.module.css';

const BannerThreeText = () => {
  return (
    <div className={`container ${styles.margins}`}>
      <br />
      <h4>Memberbutton with influencer/affiliation/referral network</h4>

      <p>
        As a hotel and property manager your goal is to increase your revenue.
        This can partly be achieved by using our free booking engine and
        generating direct bookings via your website and social media channels -
        instead of practically giving away all bookings and paying high
        commission to Online Travel Agents, which many hotels do!
      </p>

      <p>
        Memberbutton is at the same time a hybrid solution; it essentially
        functions as a commission-free OTA, with a free-standing booking page
        for each Member hotel, while at the same time being integrated with the
        influencer marketing solution and search engine.
      </p>

      <br />

      <div className='row'>
        <div className='col-sm'>
          <p>
            Via Memberbutton hotels only pay commission on generated and
            completed bookings via the affiliations/referral network – and at a
            lower cost than usually charged by OTAs. In addition, by using
            Memberbutton’s booking page hotels automatically benefit from our
            affiliations/referral network that helps transform social
            influencers, bloggers, and other partners into small travel agents
            who work hard to promote offers from the properties.
          </p>
        </div>

        <div className='col-sm'>
          <p>
            As you may know, bloggers and publishers typically have a good
            number of readers and followers that businesses can take advantage
            of and they often have access to the relevant target audience
            businesses need. It is crucial to have effective and strong outreach
            on social media, where hotels' affiliations with influencers drive
            engagement and loyalty, especially with independent travellers.{' '}
          </p>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <p className='col-sm'>
          <span className={styles.span_text}>Do note: Memberbutton</span> is not
          an affiliate programme but instead offers an affiliate network, which
          mainly consists of influencers, bloggers, certain news publishers, and
          also local businesses (that can promote the hotels). Hotels that are
          listed on Memberbutton will be more likely to be promoted by genuine
          influencers, bloggers and affiliates.
        </p>
        <hr />
        <p className='col-sm'>
          The booking engine comes fully integrated with SocialHub, our sister
          business which is a brand new social network, created to help
          businesses to better engage with their customers. Its ‘Amplify’ link &
          tracking solution makes your property accessible to our registered and
          approved promoters in the network. This enables all the power of
          "community and influencer reach" to be amplified with help of people
          around us “influencers” who share authentic stories, and
          recommendations to their followers. It also reduces your reliance on
          OTAs and the high cost connected with that.{' '}
        </p>
        <hr />
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <p className='col-sm'>
          As long as you have worthwhile incentives to offer your potential
          guests and you make the rate better than what you offer via other
          third party online travel agents, your guests should be eager to book
          and the influencers will be eager to promote your offers to their
          followers
        </p>

        <p className='col-sm'>
          This influencer marketing programme for Member Stays (hotels and
          vacation rentals) rewards everyone involved; from the person booking
          the room getting a special discount to you as the hotel or property
          owner seeing your influencer-powered campaigns taking off and giving
          results...all while disrupting the OTA's!{' '}
        </p>
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <div className='col-sm'>
          <h6>Value-added deals could look like this:</h6>
          <ul className={styles.list}>
            <li>A free complimentary breakfast</li>
            <li>A free night’s stay or free beverages</li>
            <li>Discounts on a meal at nearby restaurant you partner with</li>
            <li>Free upgrades or VIP access (such as club room)</li>
            <li>
              For vacation rentals, you can offer extra amenities on arrival
            </li>
          </ul>
        </div>

        <br />

        <div className='col-sm'>
          <h6 className={styles.line_height}>
            By joining Memberbutton.com you will as a hotel property get:
          </h6>
          <ul className={styles.list}>
            <li>
              Free instant booking page (0% commission on direct bookings)
            </li>
            <li>
              Free listing on{' '}
              <a href='/' className={styles.text_color}>
                memberbutton.com
              </a>{' '}
              (for your guests)
            </li>
            <li>
              Free listing on{' '}
              <a
                href='https://app.memberbutton.com/'
                className={styles.text_color}
              >
                app.memberbutton.com
              </a>{' '}
              (booking engine for influencers to book stays)
            </li>
            <li>
              Free group listing{' '}
              <a href='https://socialhub.center/' className={styles.text_color}>
                socialhub.center (social network)
              </a>
            </li>
            <li>
              Control bookings with a free channel manager/extranet (and connect
              with main OTA's and AirBNB).
            </li>
          </ul>
        </div>
      </div>

      <br />
      <hr />

      <p>
        <span className={styles.span_text}>
          <a
            href='https://chat.socialhub.center/memberbutton/channels/town-square'
            className={styles.text_color}
          >
            {' '}
            To learn more and or get intouch, please join our chat group:{' '}
          </a>
        </span>
      </p>

      <br />
      <hr />

      <div className='row'>
        <div className='col-sm'>
          <p>
            Both hosts and influencers are able to access their own campaign
            analytics dashboard with real-time reporting, tracking their
            performance and earnings. SocialHub's tracking solution is built for
            marketers to understand exactly how each influencer campaign is
            impacting their goals, see real-time reporting on conversions,
            clicks, and track influencer commissions.
          </p>
        </div>
        <div className='col-sm'>
          <p>
            Payouts are all handled and set up by SocialHub without any fixed
            monthly or yearly fees.
          </p>
          <p>
            Amplify's analytics and real-time reporting is also built for
            marketers so they can understand exactly how each publisher is
            impacting their campaign goals - managed by SocialHub.
          </p>
        </div>
      </div>

      <hr />
      <br />

      <div className='row'>
        <p className='col-sm'>
          Moreover, hotels will be less dependent on big OTA’s and Internet
          giants because hotels/properties can now get a completely new
          marketing channel, but with lower costs and better efficiency.{' '}
        </p>
        <hr />
        <p className='col-sm'>
          Memberbutton can also highlight that when you work with a neutral
          platform such as SocialHub, it becomes more of a partnership, and
          plenty of people are willing to click on posts to study the content
          and promotions, which leads to higher conversions. And who knows, they
          might even refer it to other people—thereby increasing your reach.{' '}
        </p>
        <hr />
      </div>

      <hr />
      <br />

      <div>
        <h6>Premium property-specific campaign page</h6>
        <p>
          For hotel properties that so wish, Memberbutton can also facilitate a
          Premium solution that provides a dedicated campaign page that can be
          promoted individually aside from the free listing (normally the
          affiliate network links to the Memberbutton search) and that will be
          visible as a promo page on Memberbutton.com. This enables a hotel or
          hotel brand to use the Memberbutton system for exclusive promotion of
          its hotel (s) – using its own influencers too, if it so wishes (in
          addition to the Member affiliates), where the link will go directly to
          the campaign page.
        </p>
        <p>
          This is offered as a monthly subscription, per our price list.{' '}
          <span className={styles.span_text}>(contact us to learn more)</span>
        </p>
      </div>

      <hr />
      <br />

      <div>
        <p>
          Host influencers on{' '}
          <a href='https://app.memberbutton.com/' className={styles.text_color}>
            {' '}
            App.Memberbutton{' '}
          </a>
        </p>
      </div>

      <hr />
      <br />

      <div>
        <h6>
          Memberbutton allows hotels to be listed to allow influencers to book
          stays:
        </h6>
        <p>
          Simplifying the booking of hotels for influencers! Memberbutton also
          facilitates the hosting of influencers at hotels in an easy and
          professional manner. Here, the hotel can set up its conditions towards
          the network of registered and approved social influencers, bloggers
          and publishers. The hotel pays commission based on generated bookings,
          aside any additional hosting perks offered as part of a hosted stay.
        </p>
        <p>
          After each hosting, the hotel can also review the hosted influencer
          within the Memberbutton system, so that other hotels can learn about
          the result and what was achieved with each particular influencer.
        </p>
        <p>Likewise the influencer can share her/his review via SocialHub.</p>
      </div>
      <hr />
    </div>
  );
};

export default BannerThreeText;
