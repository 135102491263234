import React from "react";

import Footer from 'components/home_footer'
import BannerThreeText from "components/banner3_text";

import styles from './banner3_page.module.css'

import first from 'static/first.jpg'

import Nabar from "components/Nabar";
import MainSearch from "components/main_search";

const BannerThreePage = () => {

  return ( 
    <div>
      <Nabar/>
      <div className='hero-banner magic-ball home'>
        <div className='main-banner'>
          <img src={first} alt='front pic' />
          <MainSearch banner={true}/>
        </div>
      </div>

        <BannerThreeText/>

      <div className={styles.footer}>
          <Footer/>
      </div> 
    </div>
  );
}

export default BannerThreePage;