import React from 'react';
import styles from './banner2.module.css';

const BannerTwoText = () => {
  return (
    <div className={`container ${styles.margins}`}>
      <br />
      <h4>About Memberbutton’s “zero”-commission booking engine</h4>

      <p>
        Get a 100% free and easy-to-use booking system for your website with
        built-in influencer marketing program. Memberbutton does not charge any
        monthly fees or commission on direct bookings generated by the
        property’s own website or social channels..
      </p>

      <p>
        For small hotels who may currently use only booking.com as a booking
        engine because they don't have their own booking page. Memberbutton can,
        thanks to its overall concept, be the better solution to fill that void
        – for free!
      </p>

      <br />

      <div className='row'>
        <div className='col-sm'>
          <p>
            Memberbutton.com provides a free and user-friendly booking landing
            page for hotels, resorts, BNB’s or Vacation Rentals so they can add
            their unique booking promotions fully focused on the promotion
            itself. The properties can use their dedicated landing page as their
            main booking page for their website and social media channels.
            Alternatively they can use it as a promotions-focused booking page
            (for example regarding holiday promotions, special events or even
            members-only promotions). So Memberbuttton can – and for good
            reasons thanks to its innovative technology – function as an
            additional revenue stream for hotels that already have their own
            booking engine.
          </p>
        </div>

        <br />

        <div className='col-sm'>
          <p>
            <span className={styles.span_text}>
              Established and larger hotels
            </span>{' '}
            can also use this system to market only deals and promotions via the
            Member affiliation network of influencers, as well as referrals from
            individual Members.
          </p>
          <p>
            Memberbutton’s Extranet is powered by Channex.io - channel manager
            but with our own portal. All listed properties can also connect this
            channel manager to the top OTA’s (online travel agents like
            Booking.com, Agoda, Expedia, AirBNB and HotelBeds). Properties using
            a PMS (property management system) can connect the Memberbutton
            extranet to their PMS as well.
          </p>
          <br />
        </div>
      </div>

      <br />
      <br />

      <div className='row'>
        <p className='col-sm'>
          All the properties that use the free booking page provided by
          Memberbutton.com will be listed in the marketplace, so anyone can
          visit Memberbutton.com to find properties and book their next stay.
          Should the customer cancel, the hotel only needs to fill in a
          cancellation request form.
        </p>
        <hr />
        <p className='col-sm'>
          It is also worth pointing out that each hotel has a direct
          relationship with the customer who books a Member Stay with them, and
          with help of SocialHub’s Chat & Support, Memberbutton will help
          facilitate the relationship between hotels and guests.{' '}
        </p>
        <hr />
      </div>

      <br />
      <br />

      <div className='row'>
        <p className='col-sm'>
          {' '}
          Example of what a hotel booking page can look like, and comes with
          100% free direct bookings:{' '}
          <span className={styles.span_text}>
            <a
              href='/98588b4a-2903-4d64-9987-78836fc881d6'
              className={styles.text_color}
            >
              {' '}
              Click here:{' '}
            </a>
          </span>
          A button can be added with a link to such a page on the hotel’s own
          social media channels or website.
        </p>

        <hr />
      </div>
      <hr />
    </div>
  );
};

export default BannerTwoText;
