import React from "react"
import { Carousel } from "react-bootstrap"

import first from 'static/first.jpg'
import second from 'static/second.jpg'
import third from 'static/third.jpg'
import fourth from 'static/fourth.jpg'
import styles from './slider.module.css'

const Slider = () => {

    return ( 
      <Carousel fade>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            src={first}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className={`sv_home_subsec text-center`}>
              <h2 className={`banner-title ${styles.banner_margin_bott}`}>Book unique hotels endorsed</h2>
              <span className="banner-title">by influencers</span>
              </div> 
                
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            src={second}
            alt="Second slide"
          />

          <Carousel.Caption>
          <div className={`sv_home_subsec text-center`}> 
                                  <h2 className={`banner-title ${styles.banner_margin_bott}`}>Find exclusive hotel offers</h2>
                                  <span className="banner-title">within our referral network</span>
                              </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            src={third}
            alt="Third slide"
          />

          <Carousel.Caption>
          <div className={`sv_home_subsec text-center`}>
                                  <h2 className={`banner-title ${styles.banner_margin_bott}`}>Online lodging marketplace</h2>
                                  <span className="banner-title">shared by friends you trust</span>
                              </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            src={fourth}
            alt="Third slide"
          />

          <Carousel.Caption>
          <div className={`sv_home_subsec text-center size`}>   
                                  <h2 className={`banner-title ${styles.banner_margin_bott}`}>Influencer-curated hotel deals</h2>
                                  <span className="banner-title">with lowest rates online</span>
                              </div>
          </Carousel.Caption>
        </Carousel.Item>
        
      </Carousel>
    )
}

export default Slider;