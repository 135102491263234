import React from "react";

import Footer from 'components/home_footer'
import BannerOneText from "components/banner1_text";

import styles from './banner1_page.module.css'

import third from 'static/third.jpg'

import Nabar from "components/Nabar";
import MainSearch from "components/main_search";

const BannerOnePage = () => {

  
  return ( 
    <div>
      <Nabar/>
      <div className='hero-banner magic-ball home'>
        <div className='main-banner'>
          <div className={styles.image}>
            <img src={third} alt='front pic' />
              <MainSearch banner={true}/> 
          </div>
        </div>
      </div>

      <BannerOneText />

      <div className={styles.footer}>
          <Footer/>
      </div> 
    </div>
  );
}

export default BannerOnePage;