import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

import Footer from 'components/home_footer';
// import Middle from "components/Cards";
import Slider from 'components/slider/slider';
import MainSearch from 'components/main_search';

import { AppDataContext } from 'containers/data_context';

import EmptyIcon from 'static/empty-property.svg';
import styles from './home_page.module.css';
import Nabar from 'components/Nabar';
import Banner from 'components/banner';

export default function HomePage() {
  const { t } = useTranslation();
  const { featureFlags } = useContext(AppDataContext);

  // useEffect(() => {
  //   console.log(document.getElementById('head_script'));
  //   const script = document.createElement('script');
  //   script.src = 'https://cdn.boei.help/hello.js';
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  if (!featureFlags.searchPageIsActive) {
    return (
      <div className={styles.emptyWrapper}>
        <img src={EmptyIcon} alt={t('properties:unActiveSearchPage')} />
      </div>
    );
  }

  return (
    <div>
      <Nabar />
      <div className='hero-banner magic-ball home'>
        <div className='main-banner'>
          <div>
            <Slider />
            <MainSearch />
          </div>
        </div>
      </div>
      <Banner />
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText='OK'
        cookieName='myAwesomeCookieName2'
        style={{ background: '#000', paddingLeft: '10%', paddingRight: '10%' }}
        buttonStyle={{
          color: '#4e503b',
          fontSize: '13px',
          background: '#fff',
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}
        expires={150}
      >
        We use cookies to provide a better service. Continue to use the site if
        you're happy with this, or find out a way{' '}
        <a
          href='https://www.google.com'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.cookie}
        >
          how to disable cookies{' '}
        </a>
        <span style={{ fontSize: '14px' }}>
          <a
            href='/faq'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.cookie}
          >
            {' '}
            More Info{' '}
          </a>
        </span>
      </CookieConsent>
    </div>
  );
}
