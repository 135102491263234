import React from 'react';
// import { Link } from "react-router-dom"
import styles from './banner.module.css';

import Banner1 from './banner1';
import Banner2 from './banner2';
import Banner3 from './banner3';
import Banner4 from './banner4';

const Banner = () => {
  return (
    <div className='mt-5 mb-5'>
      <div className={styles.banner_wrapper}>
        <h4 className={styles.heading}>Book Stays</h4>
        <Banner1 />

        <h4 className={styles.heading}>For Hotels</h4>
        <Banner2 />

        <h4 className={styles.heading}>For Influencers</h4>
        <div className={styles.banner3}>
          <Banner3 />
        </div>

        <h4 className={styles.heading}>Partner with Us</h4>
        <Banner4 />
      </div>
    </div>
  );
};

export default Banner;
