import React from 'react';
import styles from './banner4.module.css';

const BannerFourText = () => {
  return (
    <div className={`container ${styles.margins}`}>
      <br />
      <h4>Memberbutton for influencers</h4>

      <p>
        Memberbutton represents the ultimate solution that enables all our
        member stakeholders to meet, collaborate and benefit based on
        simplicity, consumer-driven marketing participation and increased direct
        bookings (with win-win including lower costs for properties)
      </p>

      <p>
        Influencers as Members play a key role in the Memberbutton concept –
        which you can use in two essential ways; both intended to generate
        increased revenue and also income for themselves.
      </p>

      <hr />
      <br />

      <div className='row'>
        <div className='col-sm'>
          <p>
            First, as an influencer you can become part of our professional
            affiliations network, to generate referral bookings and earn
            commission. By joining our SocialHub social network you can also be
            registered to promote all the listed Member Stays, deals and
            experiences on Memberbutton.com.
          </p>
        </div>

        <br />

        <div className='col-sm'>
          <p>
            Also, it ought to be a good opportunity for all influencers to
            promote hotels where they have stayed, or to find other hotels to
            promote too, on one and the same marketplace. On Memberbutton the
            mutual interest and understanding of the value of using influencers
            in this manner prevails.
          </p>
          <br />
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <div className='col-sm'>
          <p>
            An affiliate who registers and embeds a banner/affiliate link onto
            their web/social media pages can have a much higher income
            opportunity per click and sales generated (than Google Adsense) plus
            a promise of recurring income on future sales. After registering and
            adding the tracking link with an image the affiliate can watch the
            magic happen. In your reporting dashboard, you can see the real-time
            earnings from your efforts, and can request a payout to get your
            funds transferred via paypal or wire-transfer.
          </p>
        </div>

        <br />

        <div className='col-sm'>
          <p>
            Second, they can apply to deals/influencer stays listed in the
            website{' '}
            <a
              href='https://app.memberbutton.com/'
              className={styles.text_color}
            >
              app.memberbutton
            </a>{' '}
            by influencer hosts (Member hotels) that have opted in to do so.
          </p>
          <p>
            Some hotels will know the value of hosting an influencer at their
            property for an experience and for the influencer to be able to
            enhance their marketing via photos, audiovisuals, and informative
            text based on on-site insights.
          </p>
          <br />
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <div className='col-sm'>
          <p>
            ‘Member Stays’ for influencers facilitates this service, mainly as a
            way to strengthen and maximise the marketing/influencer
            collaborations. Furthermore, as a system it facilitates a safe way
            to ensure commitment and collaboration on commercial terms between
            hotels and marketers. And the system really enables any visitor to
            become a brand ambassador and an influencer that markets your hotel:
            any particular hotel customer can also sign up to the Memberbutton -
            and be rewarded for any new customers they can generate.
          </p>
        </div>

        <br />

        <div className='col-sm'>
          <p>
            Each hotel must set their criteria, and what will be expected from a
            marketer in return for, say a free, or discounted stay including
            food and drink hosting. As a marketer you will be expected to prove
            your worth in the form of previous coverage, references, volume of
            and demographics of your own social media followers etc.
          </p>
          <br />
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div className='row'>
        <p>
          {' '}
          Influencers can post a review of the hotel after booking and staying,
          via{' '}
          <a href='https://app.memberbutton.com/' className={styles.text_color}>
            app.memberbutton
          </a>{' '}
          while they can also be reviewed by the host, as a follow-up on any
          collaboration.
        </p>
        <p>
          Influencers can also earn extra by offering to provide content (text,
          photos, audio-visual) to the hotel host.
        </p>

        <hr />
      </div>
      <hr />
    </div>
  );
};

export default BannerFourText;
