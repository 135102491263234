import React from 'react';

import styles from './banner4.module.css';

const Banner4 = () => {
  return (
    <div className={`row ${styles.celebrate}`}>
      <div className={`col-md-12 ${styles.celebrate_back}`}>
        <h2 className={`font-weight-400 mb-2 text-white`}>
          Influencer & hotel brand partnerships
        </h2>
        <p className='text-14 text-white'>
          Memberbutton will also initiate – and is open for – partner
          constellations such as dedicated <br /> marketing of local clusters of
          hotels or brands, where hotels and a selection of our Member
          <br /> influencers and affiliates are paired up.
        </p>
        <a href='/memberbutton-partnerships'>
          <button className='p-3 rounded-4 border-0 font-weight-500 mt-5'>
            Learn More
          </button>
        </a>
      </div>
    </div>
  );
};

export default Banner4;
