import React from "react";

import styles from './banner2.module.css'

const Banner2 = () => {
    return (
        <div className={`row ${styles.tryhosting}`}>  
            <div className={`col-md-12 ${styles.back}`}>
                    <h2 className={`font-weight-400 mb-2 text-white`}>
                        A free "zero" - commission booking engine for hotels
                    </h2>
                    <p className="text-16 text-white">
                        Get a 100% free and easy-to-use booking system for your website with<br/>
                        built-in influencer marketing program, where you only pay commission on <br/> 
                        referral-generated bookings! Established and larger hotels can also use <br/> 
                        this system to market only deals and promotions via the <br/> 
                        Member affiliation network of influencers.
                    </p>
                    <a href="/memberbutton-engine">
                        <button className="p-3 rounded-4 border-0 font-weight-500 mt-5">
                            Learn More
                        </button>
                    </a>
                    
            </div> 
        </div> 
    )
}

export default Banner2;