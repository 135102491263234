import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import RoomDetailsModal from './room_details_modal';

import styles from './room_title.module.css';

export default function RoomTitle({ room }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);
  // console.log('Room : ', room);

  return (
    <>
      <div className={styles.title}>
        <strong>{room.title}</strong>
      </div>
      {room.photos.length ? (
        <Button
          variant='link'
          className={styles.title}
          onClick={handleModalToggle}
        >
          <img
            src={room.photos[0].url}
            alt='room info'
            className={styles.room_photo}
          />
        </Button>
      ) : (
        ''
      )}
      <RoomDetailsModal
        room={room}
        show={isModalOpen}
        onHide={handleModalToggle}
      />
    </>
  );
}
